import {
  ESPECIAL_CHARACTER,
  EXTREME_ACTIVITY_CATEGORY_ID,
  IMAGES_ROUTE,
  TOURIST_SERVICE_TYPE,
} from './constants';
import { getCurrentUser } from '../services/cognito';
import { addHours } from 'date-fns';

export const splitField = (arg: string, locale: string) => {
  let splitedString = arg?.split(ESPECIAL_CHARACTER);
  if (splitedString) {
    let textEnglish = splitedString[1] ?? '';
    let textSpanish = splitedString[0] ?? '';

    if (locale === 'en') {
      return textEnglish === '' ? textSpanish : textEnglish;
    }
    return textSpanish;
  }
};

export const splitFieldForms = (arg: string) => {
  let splitedString = arg?.split(ESPECIAL_CHARACTER);
  return {
    spanish: splitedString ? splitedString[0] : '',
    english: splitedString ? splitedString[1] : '',
  };
};

export const validatePasswordStrength = (password: string): number => {
  let strength = 0;

  if (password) {
    if (password.length >= 8 && password.length <= 20) {
      strength = strength + 1;
    }

    if (/\d/.test(password)) {
      strength = strength + 1;
    }

    if (password.match(/[a-z]/) && password.match(/[A-Z]/)) {
      strength = strength + 1;
    }

    if (password.match(/[^\w\s.!@$%^&*()\-\/]+/) || password.match(/[@$!%#?&+,-.]/)) {
      strength = strength + 1;
    }
  }

  return strength;
};

export const emailIsValid = (email: string): boolean => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

export const validateMinLength = (input: any, length: number): boolean => {
  return input ? input.length >= length : false;
};

export const validateMaxLength = (input: any, length: number): boolean => {
  return input ? input.length <= length : false;
};

export const validateExactLength = (input: any, length: number): boolean => {
  return input ? input.length === length : false;
};

export const getImageAsDataURL = (event) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onloadend = (loadEvent) => resolve(loadEvent.target.result);
    reader.onerror = (error) => reject(error);
  });
};

export const getMunicipalityName = (
  municipalityID,
  municipalitiesList = []
) => {
  return municipalitiesList.find(
    (municipality) => municipality.id_municipio === Number(municipalityID)
  )?.nombre;
};

export const getDepartmentName = (departmentID, departmentsList = []) => {
  return departmentsList.find(
    (department) => department.id_departamento === Number(departmentID)
  )?.nombre;
};

export const getDepartmentByMunicipality = (
  municipalityID,
  municipalitiesList,
  departmentsList
) => {
  return departmentsList?.find((department) => {
    return (
      department.id_departamento ===
      getMunicipalityDepartmentID(municipalityID, municipalitiesList)
    );
  });
};

export const getMunicipalityDepartmentID = (
  municipalityID,
  municipalitiesList
) => {
  if (municipalitiesList === undefined) return;
  return municipalitiesList.find((municipality) => {
    return municipality.id_municipio === Number(municipalityID);
  })?.id_departamento;
};

export const getDepartmentMunicipalities = (
  departmentID: string,
  municipalities
) => {
  const departmentMunicipalities = municipalities
    ?.filter(
      (municipality) => Number(departmentID) === municipality.id_departamento
    )
    .sort((a, b) => a.nombre.localeCompare(b.nombre));
  return departmentMunicipalities?.map((municipality) => {
    return {
      name: municipality.nombre,
      value: municipality.id_municipio,
      PST: municipality.PST,
    };
  });
};

export const getPstMunicipalities = (municipalities) => {

  const pstMunicipalities = municipalities?.sort((a, b) =>
    a.nombre.localeCompare(b.nombre)
  );

  return pstMunicipalities?.map((municipality) => {
    return {
      name: municipality.nombre,
      value: municipality.id_municipio,
      PST: municipality.PST,
    };
  });
};

export const sortArrayByName = (a, b, key) => {
  if (a[key] > b[key]) {
    return 1;
  }

  if (a[key] < b[key]) {
    return -1;
  }

  return 0;
};

export const getImageURL = (imageName) => {
  return IMAGES_ROUTE + imageName;
};

export const isAnExtremeActivity = (
  activityID: number,
  activitiesList = []
) => {
  if (activityID === undefined || activityID === null) return false;
  const activityInfo = activitiesList.find(
    (activity) => activity.id_tipo_actividad === activityID
  );
  return (
    activityInfo.id_tipo_categoria_actividad === EXTREME_ACTIVITY_CATEGORY_ID
  );
};

export const checkIfUserCanActivate = async (roleID): Promise<boolean> => {
  const currentUser = await getCurrentUser();
  if (currentUser === 'The user is not authenticated') return false;
  return Number(currentUser.attributes['custom:role_id']) === roleID;
};

export const fixDate = (dateString = new Date()) => {
  return addHours(new Date(dateString), 5);
};

export const fileSizeIsValid = (maxFileSize, file) => {
  if (maxFileSize === undefined) return true;
  return file.size <= maxFileSize;
};

export const getServiceURL = (serviceID) => {
  if (serviceID === undefined || serviceID === null) return '/404';
  return `/servicio/${serviceID}`;
};

export const getOfferTypeName = (offerTypeID, offerTypes) => {
  if (offerTypes === undefined || offerTypes.length === 0) return '';
  return offerTypes.find(
    (offerType) => offerType.id_tipo_oferta === offerTypeID
  )?.nombre;
};

export const formatPrice = (priceNumber) => {
  if (priceNumber === undefined || priceNumber === null) return '$0';
  return `$${priceNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`;
};

export const getTouristServiceTypeID = (touristService: any) => {
  if (touristService === null) return TOURIST_SERVICE_TYPE.TOURIST_PACKAGE;
  return touristService?.id_tipo_servicio_turistico;
};

export const getTouristServiceTypeName = (
  touristServiceTypeID,
  touristServiceTypes
) => {
  if (touristServiceTypes === undefined || touristServiceTypes.length === 0) {
    return '';
  }

  if (touristServiceTypeID === TOURIST_SERVICE_TYPE.TOURIST_PACKAGE) {
    return 'Paquete turístico';
  }

  return touristServiceTypes.find(
    (serviceType) =>
      serviceType.id_tipo_servicio_turistico === touristServiceTypeID
  )?.nombre;
};

export const getTouristServiceTypeNameEn = (
  touristServiceTypeID,
  touristServiceTypes
) => {
  if (touristServiceTypes === undefined || touristServiceTypes.length === 0) {
    return '';
  }

  if (touristServiceTypeID === TOURIST_SERVICE_TYPE.TOURIST_PACKAGE) {
    return 'Tour package';
  }

  return touristServiceTypes.find(
    (serviceType) =>
      serviceType.id_tipo_servicio_turistico === touristServiceTypeID
  )?.en_nombre;
};

export const getTouristServiceTypeInfo = (serviceInfo) => {
  return (
    serviceInfo.paquete_turistico ||
    serviceInfo.servicio_turistico?.actividad_turistica ||
    serviceInfo.servicio_turistico?.alojamiento ||
    serviceInfo.servicio_turistico?.gastronomia ||
    serviceInfo.servicio_turistico?.parque_tematico ||
    serviceInfo.servicio_turistico?.ruta_turistica ||
    serviceInfo.servicio_turistico?.transporte
  );
};

export const getTouristServiceTypeMuncipalityID = (serviceInfo) => {
  const touristServiceType = getTouristServiceTypeInfo(serviceInfo);

  if (touristServiceType === undefined) return null;

  return (
    touristServiceType.ubicacion?.id_municipio ||
    touristServiceType.punto_ruta_turistica?.[0].id_municipio ||
    touristServiceType.punto_transporte?.[1].id_municipio ||
    serviceInfo.sede?.id_municipio
  );
};

export const generateTitleForSEO = (title = 'Página') => {
  console.warn('Remember to define the meta tags for all pages.');
  return `${title} - Turismo Región Pacífico`;
};

export const isPasswordStrong = (password: string): boolean => {
  return validatePasswordStrength(password) === 3;
};

export const getServiceMunicipalityID = (serviceInfo: any): number => {
  let serviceMunicipalityID = 0;

  if (serviceInfo.servicio_turistico) {
    let serviceDetailInfo;

    const serviceIsTouristActivity =
      serviceInfo.servicio_turistico.id_tipo_servicio_turistico ===
      TOURIST_SERVICE_TYPE.TOURIST_ACTIVITY;
    const serviceIsTouristRoute =
      serviceInfo.servicio_turistico.id_tipo_servicio_turistico ===
      TOURIST_SERVICE_TYPE.TOURIST_ROUTE;
    const serviceIsAccommodation =
      serviceInfo.servicio_turistico.id_tipo_servicio_turistico ===
      TOURIST_SERVICE_TYPE.ACCOMMODATION;
    const serviceIsTransport =
      serviceInfo.servicio_turistico.id_tipo_servicio_turistico ===
      TOURIST_SERVICE_TYPE.TRANSPORT;
    const serviceIsGastronomy =
      serviceInfo.servicio_turistico.id_tipo_servicio_turistico ===
      TOURIST_SERVICE_TYPE.GASTRONOMY;
    const serviceIsThemePark =
      serviceInfo.servicio_turistico.id_tipo_servicio_turistico ===
      TOURIST_SERVICE_TYPE.THEME_PARK;

    if (serviceIsTouristActivity) {
      serviceDetailInfo = serviceInfo.servicio_turistico.actividad_turistica;
    }

    if (serviceIsAccommodation) {
      serviceDetailInfo = serviceInfo.servicio_turistico.alojamiento;
    }

    if (serviceIsGastronomy) {
      serviceDetailInfo = serviceInfo.servicio_turistico.gastronomia;
    }

    if (serviceIsThemePark) {
      serviceDetailInfo = serviceInfo.servicio_turistico.parque_tematico;
    }

    if (
      serviceIsTouristActivity ||
      serviceIsAccommodation ||
      serviceIsGastronomy ||
      serviceIsThemePark
    ) {
      serviceMunicipalityID = serviceDetailInfo.ubicacion.id_municipio;
    }

    if (serviceIsTouristRoute) {
      serviceDetailInfo = serviceInfo.servicio_turistico.ruta_turistica;
      serviceMunicipalityID = serviceDetailInfo.punto_ruta_turistica.find(
        (point) => point.tipo === 'llegada'
      ).id_municipio;
    }

    if (serviceIsTransport) {
      serviceDetailInfo = serviceInfo.servicio_turistico.transporte;
      serviceMunicipalityID = serviceDetailInfo.punto_transporte.find(
        (point) => point.tipo === 'llegada'
      ).id_municipio;
    }
  }

  if (serviceInfo.paquete_turistico) {
    serviceMunicipalityID =
      serviceInfo?.sede?.id_municipio ??
      serviceInfo?.paquete_turistico?.ubicacion?.id_municipio;
  }

  return serviceMunicipalityID;
};

export const getMunicipalityServiceRouteAndTransport = (serviceInfo) => {
  let serviceDetailInfo;
  let municipalities = []
  const serviceIsTouristRoute =
  serviceInfo?.servicio_turistico?.id_tipo_servicio_turistico ===
  TOURIST_SERVICE_TYPE.TOURIST_ROUTE;


  const serviceIsTransport =
      serviceInfo?.servicio_turistico?.id_tipo_servicio_turistico ===
      TOURIST_SERVICE_TYPE.TRANSPORT;

  if (serviceIsTouristRoute) {
    serviceDetailInfo = serviceInfo?.servicio_turistico?.ruta_turistica;
    municipalities.push(serviceDetailInfo.punto_ruta_turistica.find(
      (point) => point.tipo === 'llegada'
    ).id_municipio);

    municipalities.push(serviceDetailInfo.punto_ruta_turistica.find(
      (point) => point.tipo === "salida"
    ).id_municipio);
  }

  if (serviceIsTransport) {
    serviceDetailInfo = serviceInfo?.servicio_turistico?.transporte;
    for (const punto of serviceDetailInfo?.punto_transporte) {
      municipalities.push(punto.id_municipio);
    }

  }

  return  municipalities.length > 0 ? municipalities : null;
}

export const getPstCategoryName = (
  pstCategoryID,
  categoriesList = []
): string => {
  return categoriesList.find(
    (category) => category.id_categoria_PST === pstCategoryID
  )?.nombre;
};

export const formatterMoney = new Intl.NumberFormat('es-CO', {
  style: 'currency',
  currency: 'COP',
  minimumFractionDigits: 0,
});

export function MobileDetect() {
  const userAgent = typeof navigator === 'undefined' ? '' : navigator.userAgent;
  return Boolean(
    userAgent.match(
      /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
    )
  );
}

export function allowUbication(
  success: PositionCallback,
  error: PositionErrorCallback
) {
  if (!navigator.geolocation) {
    return {
      error: true,
      message: 'La geolocalización no es compatible con su navegador',
    };
  } else {
    navigator.geolocation.getCurrentPosition(success, error);
  }
}
