import { FunctionComponent } from 'react';
import IcoMoon from 'react-icomoon';

const iconSet = require('../../public/selection.json');

type IconProps = {
  icon: string;
  size: number;
  color?: string;
  className?: string;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onClick?: () => void;
};

const Icon: FunctionComponent<IconProps> = ({
  onMouseLeave,
  onMouseEnter,
  className,
  icon,
  size,
  color,
  onClick
}) => {
  return (
    <IcoMoon
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={className}
      color={color}
      title={icon}
      iconSet={iconSet}
      icon={icon}
      size={size}
      onClick={onClick}
    />
  );
};

export default Icon;
